<template>
  <b-card title="Jadwal Mengajar">
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col md="3">
          <b-form-group>
            <label>Pilih Tahun Ajaran:</label>
            <b-form-input
              placeholder="Pilih Tahun Ajaran"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>Pilih Tingkat:</label>
            <b-form-input
              placeholder="Pilih Tingkat"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="row justify-content-end mb-3">
      <b-button
        variant="gradient-primary"
        class="btn-icon"
        @click="toRoutePrint"
      >
        <feather-icon icon="UserPlusIcon" />&nbsp;Tambah Data
      </b-button>&nbsp;&nbsp;
      <b-dropdown
        text="Export"
        variant="gradient-warning"
      >
        <b-dropdown-item>
          Excel
        </b-dropdown-item>
        <b-dropdown-item>
          Word
        </b-dropdown-item>
        <b-dropdown-item>
          PDF
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="black-rhino"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="toRouteDetailPegawai(props.row.id)">
                <feather-icon
                  icon="UserCheckIcon"
                  class="mr-50"
                />
                <span>Detail</span>
              </b-dropdown-item>
              <b-dropdown-item @click="toRouteEditPegawai(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="riseUpModalRemove(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span> -->
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '50', '100']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- <Table /> -->

    <!-- <template #code>
      {{ codeAdvance }}
    </template> -->
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import Table from '@/views/table/vue-good-table/GoodTable.vue'
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import { codeAdvance } from './code'

export default {
  components: {
    // BCardCode,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'Kelas',
          field: 'nip',
        },
        {
          label: 'Wali Kelas',
          field: 'name',
        },
        {
          label: 'Ketua Kelas',
          field: 'gender',
        },
        {
          label: 'Kapasitas / Terisi',
          field: 'is_active',
        },
        {
          label: 'Keterangan',
          field: 'employee_assignments.data.position.name',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getAllEmployee()
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-kelas-add' })
    },
    toRouteEditPegawai(val) {
      this.$router.push({ name: 'data-kelas-edit', params: { id: val } })
    },
    toRouteDetailPegawai(val) {
      this.$router.push({ name: 'data-kelas-detail', params: { id: val } })
    },
    async getAllEmployee() {
      try {
        const response = await this.$http.get('/academicclasses')
        this.rows = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Parent Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/academicclasses/${val}`)
        await this.getAllEmployee()
        this.showToast('success', 'top-center', 'Delete Parent Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Parent Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
